export function isUrlValid(url) {
  return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(url);
  // return !!url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
}

export function urlAmazonModifier(url) {
  if (url.includes('amazon.com') || url.includes('a.co')) {
    // add Amazon affiliate link
    const affiliateId = 'nixtus01c-20';
    let newUrl = null;
    if (url.includes('?')) {
      newUrl = `${url.substring(0, url.lastIndexOf('?'))}?&tag=${affiliateId}`;
    } else {
      newUrl = `${url}?&tag=${affiliateId}`;
    }
    url = isUrlValid(newUrl) ? newUrl : url;
  }
  return url;
}
