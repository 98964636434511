import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const config = {
  projectId: 'gift-exchange-eaf68',
  apiKey: 'AIzaSyB2It9rxALCR9qmRUPGH_qhfjmYyWpIJrQ',
  authDomain: 'gift-exchange-eaf68.firebaseapp.com',
  databaseURL: 'https://gift-exchange-eaf68.firebaseio.com',
  // storageBucket: '*.appspot.com',
  // messagingSenderId: '142949084244',
  timestampsInSnapshots: true,
};

firebase.initializeApp(config);
export default firebase;
