import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus as plusIcon } from '@fortawesome/free-solid-svg-icons';

import GiftItem from './GiftItem';
import setUserGiftList from '../../data/setGiftListForUser';
import { getUserGiftList } from '../../store/actions/userActions';
import { urlAmazonModifier, isUrlValid } from '../../helpers/urlHelpers';
import { toastSuccess, toastError } from '../../helpers/toastHelpers';

import './giftList.scss';

const initialState = {
  showAdd: false,
  description: '',
  helperLink: '',
  errors: null,
};

class GiftList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      giftList: this.props.userGiftList ? this.props.userGiftList.giftList.map(gift => ({ ...JSON.parse(gift), edit: false })) : [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleHelperLinkChange = this.handleHelperLinkChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleEditToggle = this.handleEditToggle.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.saveGiftList = this.saveGiftList.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.userGiftList) !== JSON.stringify(this.props.userGiftList)) {
      this.setState({ giftList: this.props.userGiftList ? this.props.userGiftList.giftList.map(gift => ({ ...JSON.parse(gift), edit: false })) : [] });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleHelperLinkChange(e) {
    const url = e.target.value;
    let errors = null;
    if (url !== '' && !isUrlValid(url)) {
      errors = { helper: 'Please provide a valid link' };
    }

    this.setState({ helperLink: url, errors });
  }

  saveGiftList(updatedList, action = 'Updated') {
    setUserGiftList(this.props.userId, {
      ...this.props.userGiftList,
      giftList: updatedList.map(item => {
        delete item.edit;
        return JSON.stringify(item);
      }),
    })
      .then((response) => {
        response.success ? toastSuccess(`Successfully ${action}`) : toastError(response.message);
        this.setState({ ...initialState, giftList: updatedList }, () => this.props.getUserGiftList(this.props.userId));
      });
  }

  handleAdd(e) {
    e.preventDefault();
    if (this.state.description && this.state.description !== '') {
      const updatedList = this.state.giftList.slice();
      updatedList.push({
        description: this.state.description,
        helperLink: urlAmazonModifier(this.state.helperLink),
        edit: false,
      });

      this.saveGiftList(updatedList, 'added new Gift Item!');
    } else {
      toastError('Gift Description required Goofball!');
    }
  }

  handleEditToggle(index) {
    const giftList = this.state.giftList.slice();
    giftList[index].edit = !giftList[index].edit;

    this.setState({ giftList });
  }

  handleSave(index, updateObject) {
    const updatedList = this.state.giftList.slice();
    updatedList[index] = {
      ...updateObject,
      edit: false,
    };

    this.saveGiftList(updatedList, 'updated Gift Item!');
  }

  handleDelete(index) {
    const updatedList = this.state.giftList.slice();
    updatedList.splice(index, 1);

    this.saveGiftList(updatedList, 'deleted Gift Item!');
  }

  render() {
    return (
      <div id="gift-gift-list-container">
        <div
          className="mb-4"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h1>Gift List</h1>
            <div id="last-updated-container" style={{ fontSize: '0.8rem', marginLeft: 5 }}>
              <span style={{ fontWeight: 'bold' }}>Last Updated:</span>
              <span className="ml-2" style={{ fontWeight: 300 }}>
                {
                  this.props.userGiftList && this.props.userGiftList.lastUpdated
                    ? moment(this.props.userGiftList.lastUpdated).format('lll')
                    : 'never'
                }
              </span>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-gift-red-dark btn-lg mb-4"
            style={{ height: 60, width: 60 }}
            onClick={() => !this.state.showAdd && this.setState({ showAdd: true })}>
            <FontAwesomeIcon icon={plusIcon} sz="lg" />
          </button>
        </div>

        <div className="container hide-scrollbar" style={{ height: 'inherit', overflowY: 'scroll', scrollbars: 'none' }}>
          {
            this.state.showAdd && (

              <div className="row mb-3">
                <div className="col-sm-12">

                  <form className="gift-add">

                    <div className="form-group">
                      <label htmlFor="description">Gift Description</label>
                      <input
                        type="text"
                        className="form-control"
                        id="description"
                        name="description"
                        aria-describedby="descriptionHelp"
                        placeholder="Gift Idea"
                        onChange={this.handleChange}
                        value={this.state.description}
                        required
                      />
                      <small id="descriptionHelp" className="pl-2 form-text text-mute">
                        Describe the gift idea as clear as possible
                      </small>
                    </div>

                    <div className="form-group">
                      <label htmlFor="helperLink">Gift Helper Link</label>
                      <input
                        type="url"
                        className={`form-control${this.state.errors?.helper ? ' is-invalid' : ''}`}
                        id="helperLink"
                        name="helperLink"
                        aria-describedby="helperLinkHelp"
                        placeholder="Gift Helper Link"
                        onChange={this.handleHelperLinkChange}
                        value={this.state.helperLink}
                        required
                      />
                      <small id="helperLinkHelp" className="pl-2 form-text text-mute">
                        Is the gift idea found online? Be a pal, share a link!
                      </small>

                      {
                        this.state.errors?.helper && (
                          <div id="editHelper" class="invalid-feedback mt-2">
                            {this.state.errors.helper}
                          </div>
                        )
                      }
                    </div>

                    <button
                      type="submit"
                      className="btn btn-lg btn-success btn-block"
                      style={{ marginTop: 50 }}
                      onClick={this.handleAdd}
                      disabled={!!this.state.errors}
                    >Add</button>
                  </form>

                </div>
              </div>
            )
          }
          <div className="row">
            <div className="col-sm-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
              {
                this.state.giftList.length > 0
                  ? (
                    <div id="gift-list" style={{ marginBottom: 150 }}>
                      {
                        this.state.giftList.map((item, index) => (
                          <GiftItem
                            key={index}
                            gift={item}
                            edit={true}
                            handleEditToggle={() => this.handleEditToggle(index)}
                            handleSave={(updateObject) => this.handleSave(index, updateObject)}
                            handleDelete={() => this.handleDelete(index)}
                          />
                        ))
                      }
                    </div>
                  )
                  : (
                    <div style={{ marginLeft: 25 }}>
                      <span style={{
                        fontSize: '1.5rem',
                        fontWeight: 300,
                        fontStyle: 'italic',
                      }}>It's lonely here without any gift ideas... </span>
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  userId: state.user.userId,
  userGiftList: state.userGiftList,
});

const mapDispatchToProps = dispatch => ({
  getUserGiftList: bindActionCreators(getUserGiftList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftList);